@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  color: #333333;
  line-height: 1.6;
  overflow-x: hidden;
  background: #FFFFFF;
  font-family: "Plus Jakarta Sans", sans-serif;
}

*, p, a, ul, li, ol, h1, h2, h3, h4, h5, h6, pre, span, body, table, figure {
  margin: 0;
  padding: 0;
}

a, i,
a:hover {
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
}

a,
a:hover,
a:focus,
span {
  transition: all 0.3s;
  text-decoration: none;
}

ul,
li,
ol {
  list-style-type: none;
}

input:focus,
select:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--border_color);
}

input:-internal-autofill-selected {
  background: #FFFFFF;
}

.input-field input::placeholder {
  color: #cbcbcb;
}

select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #FFFFFF;
}

/*! ============================================================ !*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #cbceff;
}

/*! ============================================================ !*/

#wrapper {
  min-width: 360px;
}

.primary-bg-color {
  color: #FFF !important;
  background: #5B62FA !important;
}

.primary-text-color {
  color: #5B62FA !important;
}

.secondary-bg-color {
  color: #FFF !important;
  background: #9689FF !important;
}

.secondary-text-color {
  color: #9689FF !important;
}

.primary-bg-gradient {
  color: #FFF !important;
  background: linear-gradient(225deg, #9689FF 0%, #5B62FA 73.5%) !important;
}

/*! ============================================================ !*/

.meet-up-header {
  padding: 10px 20px;
  background: #FFFFFF;
}

.meet-up-header-inner {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.meet-up-logo {
  display: flex;
  color: #5f6368;
  align-items: center;
}

.meet-up-logo img {
  height: 50px;
  object-fit: contain;
  margin: 0 10px 0 0;
}

.user-login-assets .navi-bar {
  display: flex;
  align-items: center;
}

.user-login-assets .navi-bar > li > a {
  width: 48px;
  height: 48px;
  display: flex;
  padding: 12px;
  color: #888c92;
  font-size: 14px;
  line-height: 46px;
  background: #FFFFFF;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.user-login-assets .navi-bar > li > a:hover {
  color: #5f6368;
  background: #F5F5F5;
}

.user-login-assets .navi-bar > li+li {
  margin: 0 0 0 10px;
}

.data-time {
  margin: 0 20px 0 0;
}

.data-time span {
  margin: 0 2px;
  color: #5f6368;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-toggle img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
}

.dropdown-menu.dropdown-menu-customize {
  border: none;
  padding: 8px 0;
  min-width: 200px;
  max-width: 340px;
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12);
}

.dropdown-menu.dropdown-menu-customize li a {
  padding: 10px;
  color: #111111;
  display: block;
  font-size: 15px;
  overflow: hidden;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-menu.dropdown-menu-customize li a i {
  width: 18px;
  text-align: center;
}

.dropdown-menu.dropdown-menu-customize li a:hover {
  color: #111111;
  background: #F5F5F5;
}

.meet-up-content {
  padding: 30px 0;
  overflow-y: auto;
  height: calc(100vh - 70px);
}

.meet-up-inner-content {
  height: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  min-height: 450px;
  align-items: center;
  justify-content: space-between;
}

.meet-up-slider {
  overflow: hidden;
}

.meet-up-content h1 {
  font-size: 50px;
  max-width: 550px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 62px;
  margin: 0 0 30px;
}

.meet-up-content h3 {
  color: #444746;
  font-weight: 400;
  max-width: 500px;
  font-size: 1.375rem;
  line-height: 1.75rem;
  padding-bottom: 2rem;
}

.create-meeting {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.user-meeting-create {
  height: 48px;
  padding: 0 20px;
  color: #FFFFFF;
  line-height: 48px;
  border-radius: 4px;
  background: linear-gradient(225deg, #9689FF 0%, #5B62FA 73.5%);
}

.user-meeting-create::after {
  display: none;
}

.meeting-form {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.meeting-form input {
  width: 250px;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  padding: 0 15px 0 40px;
  box-sizing: border-box;
  border: 1px solid #111111;
}

.meeting-form .position-relative i {
  left: 0;
  width: 40px;
  height: 48px;
  color: #666;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  position: absolute;
}

.meeting-form button {
  width: 48px;
  height: 48px;
  border: none;
  color: #5B62FA;
  cursor: pointer;
  font-weight: 500;
  background: none;
  line-height: 48px;
  margin: 0 0 0 10px;
}

.meeting-form button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.meeting-sliders {
  max-width: 420px;
  margin: 0 auto;
}

.slider-content {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.slider-content img {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  margin: 0 auto 40px;
}

.slider-content h4 {
  margin: 0 0 12px;
}

.slider-content p {
}

.video-chat {
  height: 450px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  background: #202124;
}

.gradient-top,
.gradient-bottom {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 80px;
  position: absolute;
  background: linear-gradient(to bottom,rgba(0,0,0,0.7) 0,rgba(0,0,0,0.3) 50%,rgba(0,0,0,0) 100%);
}

.gradient-bottom {
  top: auto;
  bottom: 0;
  transform: rotate(180deg);
}

.login-user-name {
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 15px;
  cursor: default;
  font-weight: 500;
  color: #FFFFFF;
  position: absolute;
  padding: 12px 0 0 20px;
}

.open-close-camera {
  top: 50%;
  left: 50%;
  width: 100%;
  height: 56px;
  color: #FFF;
  padding: 0 20px;
  cursor: default;
  font-size: 19px;
  max-width: 300px;
  line-height: 56px;
  text-align: center;
  position: absolute;
  box-sizing: border-box;
  margin: -28px 0 0 -150px;
}

.open-close-camera span {
  left: 0;
  width: 100%;
  height: 56px;
  cursor: default;
  line-height: 54px;
  position: absolute;
  border-radius: 50px;
  transition: all 0.3s;
  background: #202124;
  border: 1px solid #27282b;
}

.tranparent-layer {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: absolute;
}

.meet-video-actions {
  left: 0;
  bottom: 0;
  z-index: 4;
  width: 100%;
  display: flex;
  padding: 20px 10px;
  position: absolute;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

.meet-video-actions a {
  width: 56px;
  height: 56px;
  margin: 0 10px;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
}

.meet-video-actions a i.fa-solid,
.meet-video-actions a i.fa-regular {
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 56px;
  color: #FFF;
  font-size: 17px;
  line-height: 55px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  transition: all 0.3s;
  border: 1px solid #ffffff;
}

.meet-video-actions a i.fa-solid:hover,
.meet-video-actions a i.fa-regular:hover {
  background: rgba(255,255,255,0.2);
}

.meet-video-actions a i.fa-solid.mice-off,
.meet-video-actions a i.fa-solid.video-off {
  z-index: 4;
  display: none;
  background: #dc362e;
  border: 1px solid #dc362e;
}

.user-presentation {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.user-presentation h2 {
  font-size: 30px;
  list-style: 40px;
  color: #000000;
}

.user-presentation p {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
  margin: 25px 0 15px;
}

.user-presentation button.primary-bg-gradient {
  height: 50px;
  width: 170px;
  border: none;
  font-size: 16px;
  margin: 0 0 25px;
  color: #FFFFFF;
  line-height: 48px;
  border-radius: 50px;
  display: inline-block;
}

.external-links {
  display: flex;
  flex-direction: column;
}

.external-links a {
  font-size: 14px;
  margin: 0 0 10px;
  padding: 6px 20px;
  border-radius: 50px;
  background: #ffffff;
}

.external-links a:hover {
  letter-spacing: 0.5px;
  background: #f7f6ff;
}

/* ======================================================= */

.start-meeting-wrap {
  height: 100%;
  color: #FFFFFF;
  position: relative;
  background: #202124;
}

.start-meeting-inner-wrap {
  position: relative;
}

.metting-members {
  /* display: flex; */
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  height: calc(100vh - 80px);
}

.metting-inner-members {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.3s;
}

.logged-in-user-view {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 10px;
  background: #2c2c2e;
  justify-content: center;
}

.logged-in-user-view video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.mice-activetion {
  top: 10px;
  z-index: 9;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: default;
  position: absolute;
  border-radius: 50%;
}

.mice-activetion i {
  width: 100%;
  height: 30px;
  font-size: 13px;
  cursor: default;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  background: #333333;
}

.mice-activetion i.fa-microphone-slash {
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  position: absolute;
}

.logged-in-user-profile-photo {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  background: rgba(255,255,255,0.2) !important;
}

.logged-in-user-profile-video {
  top: 0;
  left: 0;
  object-fit: contain;
  object-position: center;
  position: absolute !important;
}

.logged-user-name {
  left: 0;
  bottom: 0;
  z-index: 9;
  position: absolute;
}

.chat-member-room {
  overflow: hidden;
  border-radius: 8px;
  margin: 0 0 0 15px;
  transition: all 0.3s;
  background: #FFFFFF;
  width: calc(450px - 15px);
}

.chat-member-room-head {
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 15px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.chat-member-room-head h5 {
  width: 100%;
  font-size: 18px;
  color: #111111;
  overflow: hidden;
  line-height: 30px;
  font-weight: normal;
  white-space: nowrap;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.close-chat-room {
  top: 26px;
  right: 25px;
  position: absolute;
}

.close-chat-room a {
  width: 30px;
  height: 30px;
  display: block;
  color: #5f6368;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
}

.close-chat-room a:hover {
  background: rgba(0,0,0,0.03);
}

.chat-member-room-details {
  padding: 15px;
  font-size: 13px;
  color: #333333;
  overflow-y: auto;
  line-height: 21px;
  box-sizing: border-box;
  height: calc(100vh - 165px);
}

.chat-member-room-details h6 {
  font-weight: bold;
}

.join-link {
  color: #888c92;
  font-weight: 500;
  margin: 15px 0 10px;
  display: inline-block;
}

.join-copy-link {
  font-size: 14px;
  color: #5B62FA;
}

.search-people {
  margin: 10px 0 20px;
}

.search-people input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  line-height: 48px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
}

.in-meeting-members {
  margin: 10px 0;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
}

.in-meeting-members > p {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
}

.in-meeting-members ul {
  border-top: 1px solid #eaeaea;
}

.in-meeting-members ul li {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 15px;
}

.in-meeting-members ul li img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
}

.in-meeting-members ul li .list-user-view {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  width: calc(100% - 36px);
}

.in-meeting-members ul li .list-user-view .list-user-inner-view {
  width: calc(100% - 40px);
}

.in-meeting-members ul li .list-user-view .list-user-inner-view p {
  line-height: 14px;
}

.in-meeting-members ul li .list-user-view .list-user-inner-view span {
  font-size: 12px;
  color: #888c92;
  line-height: 12px;
}

.voice-view-active {
  width: 40px;
  height: 40px;
  display: flex;
  line-height: 40px;
  text-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
}

.voice-view-active i {
  width: 100%;
  height: 100%;
  color: #888c92;
  font-size: 15px;
  line-height: 40px;
  background: #FFF;
}

.voice-view-active i.fa-microphone-slash {
  top: 0;
  left: 0;
  display: none;
  position: absolute;
}

.label-info {
  color: #333;
  font-size: 11px;
  margin: 0 0 15px;
  line-height: 16px;
  text-align: center;
  padding: 10px 15px;
  border-radius: 8px;
  background: #F5F5F5;
}

.meeting-chats ul {
  overflow-y: auto;
  height: calc(100vh - 320px);
}

.meeting-chats ul li {
  padding: 10px;
  border-radius: 4px;
  transition: all 0.3s;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(0,0,0,0.03);
}

.meeting-chats ul li:hover {
  background: rgba(0,0,0,0.03);
}

.meeting-chats ul li:last-child {
  margin: 0 0;
}

.send-msg-box {
  position: relative;
}

.send-msg-box input {
  width: 100%;
  border: none;
  height: 42px;
  font-size: 13px;
  line-height: 42px;
  border-radius: 50px;
  background: #f1f3f4;
  padding: 0 45px 0 15px;
}

.send-msg-box button {
  top: 0;
  right: 0;
  width: 45px;
  height: 42px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  line-height: 42px;
  text-align: center;
  position: absolute;
}

.meeting-actions-keys {
  width: 100%;
  height: 80px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  box-sizing: border-box;
  border-top: 1px solid rgba(255,255,255,0.02);
}

.inner-keys-featurs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-link {
  display: flex;
  min-width: 200px;
  max-width: 300px;
  align-items: center;
}

.time-link li+li {
  margin: 0 0 0 15px;
  padding: 0 0 0 15px;
  border-left: 1px solid #FFFFFF;
}

.meet-video-actions a i.fa-solid.fa-phone {
  padding: 0 30px;
  background: #dc362e;
  border: 1px solid #dc362e;
}

.video-views-actions .meet-video-actions {
  position: static;
  padding: 0 10px;
}

.video-views-actions .meet-video-actions a {
  width: auto;
  height: 48px;
  margin: 0 6px;
  min-width: 58px;
  position: relative;
  border-radius: 50px;
  display: inline-flex;
  box-sizing: border-box;
}

.video-views-actions .meet-video-actions a i.fa-solid,
.video-views-actions .meet-video-actions a i.fa-regular {
  height: 48px;
  padding: 0 10px;
  font-size: 18px;
  line-height: 48px;
  border-radius: 50px;
}

.video-views-actions .meet-video-actions a.more-btn {
  min-width: 40px;
}

.video-chat-actions {
  display: flex;
  min-width: 200px;
  max-width: 300px;
  align-items: center;
  justify-content: flex-end;
}

.video-chat-actions a {
  width: 48px;
  height: 48px;
  margin: 0 5px;
  position: relative;
  border-radius: 50px;
  display: inline-flex;
  box-sizing: border-box;
}

.video-chat-actions a i {
  width: 100%;
  height: 48px;
  color: #FFF;
  font-size: 20px;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s;
}

.video-chat-actions a span {
  top: -6px;
  left: 36px;
  height: 18px;
  color: #FFF;
  font-size: 9px;
  min-width: 18px;
  padding: 1px 6px;
  font-weight: bold;
  position: absolute;
  line-height: 15px;
  text-align: center;
    dius: 50px;
  background: #5f6368;
  box-sizing: border-box;
}

.video-chat-actions a i:hover {
  background: rgba(255,255,255,0.2);
}

.other-user-meet-view .logged-in-user-view.self-login-user {
  z-index: 9;
  right: 15px;
  bottom: 15px;
  margin: 0 !important;
  width: 235px !important;
  height: 135px !important;
  min-height: 135px !important;
  position: absolute !important;
  background: #444746 !important;
}

.other-user-meet-view .logged-in-user-view.self-login-user .mice-activetion {
  right: 10px;
}

.other-user-meet-view .logged-in-user-view.self-login-user .logged-in-user-profile-photo {
  width: 66px;
  height: 66px;
}

.logged-user-name {
  left: 10px;
  bottom: 7px;
  font-size: 12px;
  font-weight: 500;
}

.metting-inner-members.other-user-meet-view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.metting-inner-members.other-user-meet-view .logged-in-user-view {
  margin: 0 auto;
  max-width: 1250px;
  background: #3c4043;
}

.metting-inner-members.other-user-meet-view.meet-users-count-2 .logged-in-user-view {
  margin: 5px;
  height: auto;
  max-width: 100%;
  width: calc(50% - 10px);
  min-height: calc(350px - 10px);
}

.metting-inner-members.other-user-meet-view.meet-users-count-3 .logged-in-user-view,
.metting-inner-members.other-user-meet-view.meet-users-count-4 .logged-in-user-view {
  margin: 5px;
  height: auto;
  max-width: 100%;
  width: calc(50% - 10px);
  min-height: calc(50% - 10px);
}